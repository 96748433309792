import {
  FOG_20,
  MOSS_100,
  OwnUpGridWrapper,
  ownUpLightTheme,
  OwnUpThemeProvider,
  ownUpWhiteTheme,
  PropsWithTheme,
  WHITE_100
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled, { css } from 'styled-components';
import { Ankle } from '../components/ankle';
import { ownUpSupportEmail } from '../components/constants';
import { HaveQuestions } from '../components/have-questions';
import { Layout } from '../components/layout';
import { CCPASection, PrivacySection } from '../components/privacy';

const backgroundColorMixin = css`
  background-color: ${({ theme, fallback }: PropsWithTheme<{ fallback: string }>) =>
    theme.backgroundColors.tertiaryTheme?.backgroundColor ||
    theme.backgroundColors.primaryTheme?.backgroundColor ||
    fallback};
`;

const WhiteBackground = styled.section`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0, 8)};
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(8, 0, 10)};
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0, 15)};
  }

  ${backgroundColorMixin}
`;

const GreyBackground = styled.section`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(8, 0)};
    scroll-margin-top: 4.5rem;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(15, 0)};
    scroll-margin-top: 5rem;
  }

  ${backgroundColorMixin}
`;

const Privacy = () => (
  <Layout>
    <OwnUpThemeProvider theme={ownUpWhiteTheme}>
      <WhiteBackground fallback={WHITE_100}>
        <OwnUpGridWrapper>
          <PrivacySection />
        </OwnUpGridWrapper>
      </WhiteBackground>
    </OwnUpThemeProvider>
    <OwnUpThemeProvider theme={ownUpLightTheme}>
      <GreyBackground fallback={FOG_20} id="CCPA">
        <OwnUpGridWrapper>
          <CCPASection />
        </OwnUpGridWrapper>
      </GreyBackground>
    </OwnUpThemeProvider>
    <HaveQuestions backgroundColor={MOSS_100} email={ownUpSupportEmail} />
    <Ankle />
  </Layout>
);

export default Privacy;
