import {
  FOG_10,
  FOG_100,
  FOG_40,
  OwnUpDetailMedium,
  ownUpDetailMixin,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import styled, { css } from 'styled-components';

export const OwnUpTable = styled.table`
  display: block;
  border-collapse: collapse;
  width: 1024px;
`;

export const OwnUpTableHeadingRow = styled.tr`
  background-color: ${FOG_40};
`;
export const OwnUpTableDataRow = styled.tr`
  background-color: white;
  &:nth-child(odd) {
    background-color: ${FOG_10};
  }
`;

export const OwnUpTableCellMixin = css`
  border: thin solid ${FOG_100};
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
  vertical-align: top;
`;

export const OwnUpTableHeadingCell = styled.th`
  ${OwnUpTableCellMixin}
  text-align: left;
  vertical-align: middle;
`;

export const OwnUpTableHeadingContent = styled(OwnUpDetailMedium)`
  white-space: pre-line;
`;

export const OwnUpTableDataCell = styled.td`
  ${OwnUpTableCellMixin}
  & > ul {
    margin: 0;
    padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(0, 1)};
    li {
      ${ownUpDetailMixin}
    }
  }
`;
