export * from './common';
export * from './ccpa';
export * from './ordered-list';
export * from './bulleted-list';

import {
  OwnUpBodyItalic,
  OwnUpHeadlineBook,
  PropsWithTheme,
  SLATE_100
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { lastUpdated, Preface } from '../../data/content/privacy/preface';
import { sections } from '../../data/content/privacy/privacy-data';
import { TextGridItem } from './common';
import { OrderedList } from './ordered-list';

const LastUpdatedWrapper = styled(OwnUpBodyItalic)`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  color: ${SLATE_100};
`;

export const PrivacySection = () => (
  <section>
    <TextGridItem aria-labelledby="privacytitle">
      <OwnUpHeadlineBook variant="h1" id="privacytitle">
        Privacy
      </OwnUpHeadlineBook>
      <LastUpdatedWrapper variant="body1">Last updated: {lastUpdated}</LastUpdatedWrapper>
      <Preface />
    </TextGridItem>
    <OrderedList data={sections} />
  </section>
);
