export const headers = [
  {
    content: 'Categories of personal information collected',
    width: '22%'
  },
  {
    content: 'Categories of sources from which the personal information was collected',
    width: '22%'
  },
  {
    content: 'Business or commercial purposes for which personal information was collected',
    width: '22%'
  },
  {
    content: 'Categories of third-parties with whom we may have shared personal information',
    width: '14%'
  },
  {
    content: `
        Disclosed for a commercial purpose
        [ Y / N ]
      `,
    width: '10%'
  },
  {
    content: `
        Disclosed for a business purpose
        [ Y / N ]
     `,
    width: '10%'
  }
];

export const rows = [
  {
    header:
      "Identifiers such as name, Social Security number, address, telephone number, driver's license or state identification card number, bank account number, loan identifier, credit card information, unique personal identifier, online identifier, Internet Protocol address, email address, account name, or any other similar identifiers",
    cells: [
      [
        'Information collected directly from you (including online and offline)',
        'Providers',
        'Service providers',
        'Affiliate companies',
        'Information automatically derived from use of our Services (e.g., through cookies)'
      ],
      [
        'Provide, develop, maintain and improve our Services, including the development and training of AI',
        'Process and fulfill a request or other transaction submitted to us',
        'Communicate with you (e.g., to schedule meetings with you or to answer questions relating to our Services)',
        'Provide personalized services, including content, ads, products or services',
        'To conduct surveys and offer sweepstakes, drawings, and similar promotions',
        'Marketing and advertising our Services and the services of other third parties',
        'Research and analytical purposes (e.g., identifying trends and effectiveness of marketing campaigns)',
        'For our business operations (e.g., auditing transactions and performing quality control)'
      ],
      ['Providers', 'Service providers', 'Affiliate companies', 'Applicable regulatory bodies']
    ],
    disclosures: ['Yes', 'Yes']
  },
  {
    header:
      'Characteristics of protected classifications under California or federal law (e.g., race, religion, and gender)',
    cells: [
      [
        'Information collected directly from you (including online and offline)',
        'Affiliate companies'
      ],
      [
        'Process and fulfill a request or other transaction submitted to us',
        'Provide personalized services, including content, ads, products or services'
      ],
      ['Providers']
    ],
    disclosures: ['Yes', 'Yes']
  },
  {
    header:
      'Records of personal property, property-related details, existing loan information (e.g., lender name, estimated balance, type of loan, assets owned, sources of assets)',
    cells: [
      [
        'Information collected directly from you (including online and offline)',
        'Service providers',
        'Affiliate companies',
        'Publicly accessible sources',
        'Organizations that we enter into contractual agreements with to license/purchase personal information'
      ],
      [
        'Process and fulfill a request or other transaction submitted to us',
        'Provide personalized services, including content, ads, products or services',
        'Research and analytical purposes (e.g., identifying trends and effectiveness of marketing campaigns)',
        'For our business operations (e.g., auditing transactions and performing quality control), including the development and training of AI'
      ],
      ['Providers', 'Service providers', 'Affiliate companies']
    ],
    disclosures: ['Yes', 'Yes']
  },
  {
    header:
      "Internet or other electronic network activity information, including browsing history, search history, and information regarding a consumer's interaction with an internet website, application, or advertisement",
    cells: [
      [
        'Information collected directly from you (including online and offline)',
        'Information automatically derived from use of our Services (e.g., through cookies)',
        'Affiliate companies',
        'Service providers'
      ],
      [
        'Provide, develop, maintain and improve our Services, including the development and training of AI',
        'Provide personalized services, including content, ads, products or services',
        'Marketing and advertising our Services and the services of other third parties',
        'Research and analytical purposes (e.g., identifying trends and effectiveness of marketing campaigns)'
      ],
      ['Providers', 'Service providers', 'Marketing partners', 'Affiliate companies']
    ],
    disclosures: ['Yes', 'Yes']
  },
  {
    header:
      'Professional or employment-related information (e.g., employment history) or education information',
    cells: [
      [
        'Information collected directly from you (including online and offline)',
        'Affiliate companies'
      ],
      [
        'Process and fulfill a request or other transaction submitted to us',
        'Provide personalized services, including content, ads, products and services'
      ],
      ['Providers', 'Affiliate companies']
    ],
    disclosures: ['Yes', 'Yes']
  },
  {
    header: 'Information you provide in reviews, questions, and comments',
    cells: [
      ['Information collected directly from you (including online and offline)'],
      [
        'Provide, develop, maintain and improve our Services, including the development and training of AI',
        'Marketing and advertising our Services and the services of other third parties',
        'For our business operations (e.g., auditing transactions and performing quality control)'
      ],
      ['General public', 'Providers', 'Affiliate companies']
    ],
    disclosures: ['Yes', 'Yes']
  }
];

export const summary =
  'The table below summarizes our collection and sharing practices relating to personal information, including with respect to personal information we have collected, used and disclosed in the preceding 12 months. As shown below, we may have disclosed or sold your personal information to third parties for a business or commercial purpose in the preceding 12 months. For all of the categories of personal information identified below, we may use your personal information for disclosures relating to Corporate Transactions and Special Circumstances. As such, all personal information may be shared with Corporate Transaction Recipients and Special Circumstances Recipients. Some capitalized terms used here are defined in the Privacy Policy above.';
