import { OwnUpHeadlineBook } from '@rategravity/own-up-component-library';
import React from 'react';
import { CCPASections } from '../../../data/content/privacy/ccpa-data';
import { Preface } from '../../../data/content/privacy/ccpa-preface';
import { TextGridItem } from '../common';
import { OrderedList } from '../ordered-list';

export const CCPASection = () => (
  <section aria-labelledby="CCPAtitle">
    <TextGridItem>
      <OwnUpHeadlineBook variant="h2" id="CCPAtitle">
        California Consumer Privacy Act Notice for California Consumers
      </OwnUpHeadlineBook>
      <Preface />
    </TextGridItem>
    <OrderedList data={CCPASections} />
  </section>
);
