import {
  OwnUpDetail,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import { headers, rows, summary } from '../../../data/content/privacy/ccpa-table';
import {
  OwnUpTable,
  OwnUpTableDataCell,
  OwnUpTableDataRow,
  OwnUpTableHeadingCell,
  OwnUpTableHeadingContent,
  OwnUpTableHeadingRow
} from './elements';

const PureCCPATable = () => {
  return (
    <OwnUpTable aria-label="ccpa-table" aria-describedby={summary}>
      <thead>
        <OwnUpTableHeadingRow>
          {headers.map(
            ({ content, width }: { content: string | JSX.Element; width: string }, idx) => (
              <OwnUpTableHeadingCell key={idx} style={{ width }}>
                <OwnUpTableHeadingContent>{content}</OwnUpTableHeadingContent>
              </OwnUpTableHeadingCell>
            )
          )}
        </OwnUpTableHeadingRow>
      </thead>
      <tbody>
        {rows.map(({ header, cells, disclosures }, i) => (
          <OwnUpTableDataRow key={i}>
            <OwnUpTableDataCell>
              <OwnUpDetail>{header}</OwnUpDetail>
            </OwnUpTableDataCell>
            {cells.map((bullets, idx) => (
              <OwnUpTableDataCell key={idx}>
                <ul key={idx}>
                  {bullets.map((bullet, bulletsIdx) => (
                    <li key={bulletsIdx}>
                      <OwnUpDetail>{bullet}</OwnUpDetail>
                    </li>
                  ))}
                </ul>
              </OwnUpTableDataCell>
            ))}
            {disclosures.map((disclosure, idx) => (
              <OwnUpTableDataCell key={idx}>
                <OwnUpDetail align="center">{disclosure}</OwnUpDetail>
              </OwnUpTableDataCell>
            ))}
          </OwnUpTableDataRow>
        ))}
      </tbody>
    </OwnUpTable>
  );
};

const CCPATableContainer = styled(OwnUpGridContainer)`
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4, 0)};
`;

const CCPATableGridItem = styled(OwnUpGridItem)`
  overflow: auto;
`;

export const CCPATable = () => (
  <CCPATableContainer variant="legacy">
    <OwnUpGridOffset xs={0} lg={1} />
    <CCPATableGridItem xs={12} lg>
      <PureCCPATable />
    </CCPATableGridItem>
    <OwnUpGridOffset xs={0} lg={1} />
  </CCPATableContainer>
);
