import {
  OwnUpBody,
  OwnUpBodyMedium,
  ownUpBodyMediumMixin,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  PropsWithTheme
} from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { Divider as DefaultDivider } from '../reskin-divider';

export type Data = {
  header?: string;
  content: string | JSX.Element | JSX.Element[];
};
export interface ListProps {
  data: Data[];
  level?: 1 | 2 | 3;
}

export const spacingMixin = css`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

const StyledItemContent = styled(OwnUpBody)`
  ${spacingMixin}
  & em {
    ${ownUpBodyMediumMixin}
    display: inline;
  }
`;
export const PrivacyBodyText = ({ children }: PropsWithChildren<{}>) => (
  <StyledItemContent variant="body1" component="span">
    {children}
  </StyledItemContent>
);

const StyledItemContentMedium = styled(OwnUpBodyMedium)`
  ${spacingMixin}
`;
export const PrivacyBodyTextMedium = ({ children }: PropsWithChildren<{}>) => (
  <StyledItemContentMedium variant="body1">{children}</StyledItemContentMedium>
);

const StyledItemContentInline = styled(StyledItemContent)`
  display: inline;
`;
export const PrivacyBodyTextInline = ({ children }: PropsWithChildren<{}>) => (
  <StyledItemContentInline variant="body1" component="span">
    {children}
  </StyledItemContentInline>
);

export const ListItem = styled.li`
  ${spacingMixin}
`;

// If the content isn't already in something with a `.map` function,
// put it into one to simplify processing.
export const mappable = (content: string | JSX.Element | JSX.Element[]) =>
  Array.isArray(content) ? content : [content];

export const TextWrapper = styled.div`
  ${spacingMixin}
`;

// These are complete GridContainers instead of just GridItems because the
// way they end up getting wrapped inside lists containers makes it hard to
// keep the grid rows predictable inside a higher-level container.
// TODO: the style attributes can be removed when GridItems are no longer padded
export const TextGridItem = ({ children }: PropsWithChildren<{}>) => (
  <OwnUpGridContainer variant="legacy" style={{ margin: 0 }}>
    <OwnUpGridOffset xs={0} lg={1} style={{ padding: 0 }} />
    <OwnUpGridItem xs style={{ padding: 0 }}>
      {children}
    </OwnUpGridItem>
    <OwnUpGridOffset xs={0} md={2} lg={3} style={{ padding: 0 }} />
  </OwnUpGridContainer>
);

const DividerWrapper = styled.div`
  margin: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0)};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    margin: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(8, 0)};
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    margin: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(10, 0)};
  }
`;
export const Divider = () => (
  <DividerWrapper>
    <DefaultDivider />
  </DividerWrapper>
);
