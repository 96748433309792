import { colors as libraryColors } from '@rategravity/own-up-component-library-legacy';

export const colors = {
  ...libraryColors,
  ALT_LIGHT_GREY: '#DAE0E0',
  AQUA: '#F2FBFB',
  CLASSIC_BLUE: '#4800E6', // differs from component-library BLUE
  DARKER_AQUA: '#E4EEEE',
  GREY_LINE: '#515456',
  GREY_TEXT: '#8B8B8B',
  LIGHT_GREY: '#EFEFEF',
  LIGHT_MINT: '#D9FDE9',
  MINT: '#8FF4BD',
  NIMBUS: '#5B7978'
};
