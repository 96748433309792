import {
  IconLink,
  OwnUpBody,
  OwnUpGridWrapper,
  OwnUpSmallHeadlineBook,
  SAGE_100
} from '@rategravity/own-up-component-library';
import { EmailIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/email';
import { PhoneIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/phone';
import React from 'react';
import { ownUpGeneralEmail, ownUpPhone, ownUpPhoneClientFormat } from '../constants';
import { HaveQuestionsAddress, HaveQuestionsContent, HaveQuestionsSection } from './elements';

interface HaveQuestionsProps {
  backgroundColor?: React.CSSProperties['backgroundColor'];
  email?: string;
  address?: boolean;
}

export const HaveQuestions = ({
  backgroundColor = SAGE_100,
  email = ownUpGeneralEmail,
  address
}: HaveQuestionsProps) => (
  <HaveQuestionsSection aria-label="have questions?" style={{ backgroundColor }}>
    <OwnUpGridWrapper>
      <OwnUpSmallHeadlineBook variant="h2">Questions?</OwnUpSmallHeadlineBook>
      <HaveQuestionsContent>
        <IconLink
          link={`tel:${ownUpPhoneClientFormat}`}
          icon={PhoneIcon}
          iconPlacement="left"
          size="large"
        >
          {ownUpPhone}
        </IconLink>
        <IconLink link={`mailto:${email}`} icon={EmailIcon} iconPlacement="left" size="large">
          {email}
        </IconLink>
        {address && (
          <HaveQuestionsAddress>
            <OwnUpBody variant="body1">Own Up</OwnUpBody>
            <OwnUpBody variant="body1">50 Milk Street, Floor 16</OwnUpBody>
            <OwnUpBody variant="body1">Boston, MA 02109</OwnUpBody>
          </HaveQuestionsAddress>
        )}
      </HaveQuestionsContent>
    </OwnUpGridWrapper>
  </HaveQuestionsSection>
);
