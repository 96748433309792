import { OwnUpBodyItalic } from '@rategravity/own-up-component-library';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Data, ListItem, mappable, PrivacyBodyText, spacingMixin } from './common';

const StyledBulletHeading = styled(OwnUpBodyItalic)`
  ${spacingMixin}
  display: inline;
`;
const BulletHeading = ({ children }: PropsWithChildren<{}>) => (
  <StyledBulletHeading variant="body1" component="span">
    {children}
  </StyledBulletHeading>
);

const StyledBulletedList = styled.ul`
  list-style-type: disc;
  padding-left: ${({ theme }) => theme.spacing(3)}px;
`;
export const BulletedList = ({ data }: { data: Data[] }) => {
  const listContent = data.map(({ header, content }, i) => {
    const head = header && <BulletHeading>{header} </BulletHeading>;
    const body = mappable(content).map((stanza, idx) => (
      <PrivacyBodyText key={idx}>
        {idx === 0 && head}
        {stanza}
      </PrivacyBodyText>
    ));
    return <ListItem key={i}>{body}</ListItem>;
  });
  return <StyledBulletedList>{listContent}</StyledBulletedList>;
};
