import { OwnUpUnderlineLink } from '@rategravity/own-up-component-library';
import { Link } from 'gatsby';
import React from 'react';
import {
  ownUpPhone,
  ownUpPhoneClientFormat,
  ownUpSupportEmail
} from '../../../components/constants';
import { PrivacyBodyText, PrivacyBodyTextInline, TextGridItem } from '../../../components/privacy';
import { BulletedList } from '../../../components/privacy/bulleted-list';
import { OrderedList } from '../../../components/privacy/ordered-list';
import { ownUpAddress } from '../../content';

/**
 * Content for Section 1.
 */
const cookies = [
  {
    header: 'Cookies.',
    content:
      "When you visit the Service, we may send one or more cookies — a small text file containing a string of alphanumeric characters — to your computer that uniquely identifies your browser or stores information or settings on your device. Cookies let us help you log in faster and enhance your navigation through the site. A cookie may also convey information to us about how you use the Service (e.g., the pages you view, the links you click and other actions you take on the Service), and allow us or our business partners to track your usage of the Service over time. A persistent cookie remains on your hard drive after you close your browser. Persistent cookies may be used by your browser on subsequent visits to the site. Persistent cookies can be removed by following your web browser's directions. A session cookie is temporary and disappears after you close your browser. Our Service may use HTTP cookies, HTML5 cookies and other types of local storage (such as browser-based or plugin-based local storage)."
  },
  {
    header: 'Clear gifs/web beacons.',
    content:
      'When you use the Service, we may employ clear gifs (also known as web beacons) which are used to anonymously track the online usage patterns of our Users. In addition, we may also use clear gifs in HTML-based emails sent to our users to track which emails are opened, which links are clicked by recipients, and whether our emails are forwarded. The information allows for more accurate reporting and improvement of the Service.'
  },
  {
    header: 'Web analytics.',
    content: (
      <PrivacyBodyTextInline>
        We may use third-party Web analytics services on our Service, such as those of Google
        Analytics. These service providers use the technology described above to help us analyze how
        users use the Service. The information collected by the technology (including your IP
        address) will be disclosed to or collected directly by these service providers. To prevent
        Google Analytics from using information for analytics, a user may install the{' '}
        <OwnUpUnderlineLink
          href="https://tools.google.com/dlpage/gaoptout"
          target="_blank"
          rel="noopener"
        >
          Google Analytics Opt-Out Browser Add-on
        </OwnUpUnderlineLink>
        .
      </PrivacyBodyTextInline>
    )
  }
];

const infoCollectedAutomatically = [
  {
    header: 'Log file information.',
    content:
      'Log file information is automatically reported by your browser each time you access the Service. When you use our Service, our servers may automatically record certain log file information. These server logs may include information such as your web request, Internet Protocol ("IP") address, browser type, browser language, operating system, platform type, the state or country from which you accessed the Service, software and hardware attributes (including Device ID), referring / exit pages and URLs, number of clicks and how you interact with links on the Service, domain names, landing pages, pages viewed and the order of those pages, the data and time you used the Service and uploaded or posted content, error logs, files you download, and other such information.'
  },
  {
    header: 'Location information.',
    content:
      'When you use the Service, we may automatically collect general location information (e.g., IP address, city/state and or zip code associated with an IP address) from your computer or mobile device. Please note that if you disable such features, you may not be able to access or receive some or all of the services, content, features and/or products made available via the Service.'
  },
  {
    header: 'Cookies and other technologies.',
    content: (
      <PrivacyBodyTextInline>
        We and our third-party service providers may use cookies, clear GIFs, pixel tags, beacons,
        and other technologies that help us better understand user behavior, personalize
        preferences, perform research and analytics, deliver tailored advertising, and improve the
        products and services we provide. You can choose to accept or decline certain cookies. Most
        web browsers automatically accept cookies, but your browser may allow you to modify your
        browser settings to decline certain cookies if you prefer. If you disable cookies, you may
        be prevented from accessing or taking full advantage of the Services.
        <BulletedList data={cookies} />
      </PrivacyBodyTextInline>
    )
  }
];

const collectionOfInformation = [
  {
    header: 'Information you provide us directly',
    content: [
      <PrivacyBodyText key={1}>
        The information we collect from you will vary depending on the product or service requested.
        We may ask for certain information such as your username, first and last name, birth date,
        telephone number, email address, outstanding loan balances, social security number, income,
        employment history, and other details related to your financial situation when you use our
        Service and/or register for an Own Up account. We may collect information that you
        voluntarily provide to us when using the Service. All of the information we collect from you
        directly is identified in the forms, other places where you respond to questions, or where
        you indicate preferences throughout the Service, either online or communicated through our
        customer service representatives.
      </PrivacyBodyText>,
      <PrivacyBodyText key={2}>
        We may also collect and store information you provide about other people. By submitting
        information about someone other than yourself, you represent that you are authorized to
        provide us with that person&apos;s information for the purposes identified in this Privacy
        Policy and/or in connection with the Service.
      </PrivacyBodyText>,
      <PrivacyBodyText key={3}>
        We may also retain any messages you send through the Service or customer service
        correspondence (via telephone, chat, text, email or otherwise). Calls to Own Up may be
        recorded or monitored for quality assurance, customer service, training, and/or risk
        management. We may also collect your information through the use of our automated chatbot,
        &quot;Katina&quot;.
      </PrivacyBodyText>
    ]
  },
  {
    header: 'Information that is collected automatically',
    content: [
      <PrivacyBodyText key={1}>
        In addition to the information you provide to us directly, we may automatically collect
        information about your use of the Service, as described below.
      </PrivacyBodyText>,
      <PrivacyBodyText key={2}>
        <BulletedList data={infoCollectedAutomatically} />
      </PrivacyBodyText>,
      <br key={3} />,
      <PrivacyBodyText key={4}>
        We may use this information in a number of ways, including: (a) to remember information so
        that you will not have to re-enter it during your visit or the next time you visit the site;
        (b) to provide custom, personalized content and information; (c) to provide our Service and
        monitor its effectiveness, including on or through our affiliates and/or other third party
        service providers; (d) to monitor aggregate metrics such as total number of visitors,
        traffic, and demographic patterns; (e) to diagnose or fix technology problems; (f) to help
        you efficiently access your information after you sign in and; (g) to deliver advertising to
        you or (h) provide further training and development of our automated chatbot “Katina”.
      </PrivacyBodyText>
    ]
  },
  {
    header: 'Information from Third-Party sources',
    content: (
      <PrivacyBodyText>
        We may receive information about you from publicly and commercially available sources, as
        permitted by law, which we may combine with other information we receive from or about you.
        For example, when you request prequalified rates, we will ask your permission to conduct a
        soft credit inquiry. Likewise, when you apply for a loan product, either we or a Provider
        may conduct a hard credit inquiry. When you apply for a mortgage, we will conduct a search
        of public records to determine the estimated value of your home and your estimated taxes in
        order to automate the loan application process and make things more convenient and
        streamlined for you.
      </PrivacyBodyText>
    )
  }
];

const collectionOfYourInformation = {
  header: 'Collection of your information',
  content: [
    <TextGridItem key={1}>
      <PrivacyBodyText>
        We may collect and store various information about you including, for example, information
        that you provide to us, information that we automatically collect through your use of the
        Service, and information from publicly available sources or third parties, as further
        detailed below. If you utilize our Services to obtain a financial product or service through
        us, we will use and share any information that we collect from or about you in accordance
        with this policy, which offers you certain choices with respect to the use and sharing of
        your personal information. Information subject to this policy is referred to as Non-Public
        Personal Information (&quot;NPI&quot;).
      </PrivacyBodyText>
    </TextGridItem>,
    <TextGridItem key={2}>
      <OrderedList level={2} data={collectionOfInformation} />
    </TextGridItem>
  ]
};

/**
 * Content for Section 2.
 */
const doNotTrackList = [
  {
    header: 'Do Not Track',
    content: (
      <PrivacyBodyText>
        Some Internet browsers may be configured to send &quot;Do Not Track&quot; signals to the
        online services that you visit. We currently do not respond to &quot;Do Not Track&quot; or
        similar signals. To find out more about &quot;Do Not Track,&quot; please visit{' '}
        <OwnUpUnderlineLink href="http://www.allaboutdnt.com" target="_blank" rel="noopener">
          http://www.allaboutdnt.com
        </OwnUpUnderlineLink>
        .
      </PrivacyBodyText>
    )
  },
  {
    header: 'Use of Cookies',
    content:
      'Own Up may use Cookies to store or retrieve information. Cookies are a small piece of text stored on Your browser which may allow Own Up to identify You upon return to the website or to remember specific information such as Your display options. Cookies are neither a virus nor spyware. You hereby authorize the use of cookies by Own Up.'
  }
];

const doNotTrackSignals = {
  header: 'Do not track signals and similar mechanisms',
  content: (
    <TextGridItem>
      <OrderedList level={2} data={doNotTrackList} />
    </TextGridItem>
  )
};

/**
 * Content for Section 3.
 */
const useOfInformation = [
  {
    header: 'To provide you with and manage the Service.',
    content:
      'We use your information to provide, develop, maintain and improve our Service and the features and functionality of the Service. This may include customization and tailoring the Service features according to the information that you provide.'
  },
  {
    header: 'To process and fulfill a request or other transaction submitted to us.',
    content:
      'We use your information to process or fulfill requests or transactions that you have requested.'
  },
  {
    content: 'To share information about you with the lenders in our lender marketplace.'
  },
  {
    header: 'To communicate with you.',
    content:
      'We use the information we collect or receive to communicate directly with you. We may send you emails containing newsletters, promotions and special offers. If you do not want to receive such email messages, you will be given the option to opt out or change your preferences. We also use your information to send you Service-related emails (e.g., account verification, changes/updates to features of the Service, technical and security notices). You may not opt out of Service-related emails.'
  },
  {
    header: 'Provide personalized services, including content, ads, products or services.',
    content: (
      <PrivacyBodyText>
        We may use the information we collect to provide personalized services and advertising to
        you either through Own Up, Own Up&apos;s affiliates or a third party marketing partner,
        subject to the requirements of the{' '}
        <OwnUpUnderlineLink to="/assets/privacy/glba-notice.pdf" component={Link}>
          Privacy Notice
        </OwnUpUnderlineLink>
        .
      </PrivacyBodyText>
    )
  },
  {
    header: 'Surveys, Offers, Sweepstakes.',
    content:
      'We may use your information to conduct surveys and offer sweepstakes, drawings, and similar promotions.'
  },
  {
    header: 'Marketing and Advertising.',
    content:
      'We may use your information to market and advertise our services and the services of certain third parties we work with.'
  },
  {
    header: 'Unique Identifiers.',
    content:
      'Sometimes, we, our affiliates or our service providers may use the information we collect - for instance, log-in credentials, IP addresses and unique mobile device identifiers - to locate or try to locate the same unique users across multiple browsers or devices (such as smartphones, tablets, or computers), or work with providers that do this, in order to better tailor advertising, content, and features, and provide you with a seamless experience across devices. If you wish to opt out of cross device tracking for purposes of interest-based advertising, you may do so through your Android or Apple device-based settings.'
  },
  {
    header: 'Research and Analytics.',
    content:
      'We may use your information for research and analytical purposes, for example, to identify trends and effectiveness of certain marketing campaigns we run.'
  },
  {
    header: 'Business Operations.',
    content:
      'We may use your information for certain operational purposes including, for example, to perform audits and quality control.'
  },
  {
    header: 'To protect the rights of Own Up and others.',
    content:
      'We may use your information as we believe is necessary or appropriate to protect, enforce, or defend the legal rights, privacy, safety, security, or property of Own Up, its affiliates, employees or agents, or other users and to comply with applicable law.'
  },
  {
    header: 'With your consent.',
    content: 'We may otherwise use your information with your consent or at your direction.'
  },
  {
    header: 'To aggregate/de-identify data.',
    content:
      'We may aggregate and/or de-identify information collected through the Service. We may use de-identified and/or aggregated data for any purpose and without any restrictions, including without limitation for research and marketing purposes, and may also share such data with any third parties, including advertisers, promotional partners, sponsors, event promoters, and/or others.'
  }
];

const useOfYourInformation = {
  header: 'Use of your information',
  content: [
    <TextGridItem key={1}>
      <PrivacyBodyText>
        We may use your information and/or combine any or all elements of your information for a
        number of purposes, including:
      </PrivacyBodyText>
    </TextGridItem>,
    <TextGridItem key={2}>
      <BulletedList data={useOfInformation} />
    </TextGridItem>
  ]
};

/**
 * Content for Section 4.
 */
const sharingWithProviders = [
  {
    content:
      'You agree that when you submit information to a Provider or third-party through the Service, such submission of your information may also be governed by Provider or third-party privacy and security policies which differ from our Privacy Policy. For details of these policies, please consult the policies of the Providers or third-parties directly.'
  },
  {
    content:
      "You understand that, subject to any applicable laws or regulations, Providers may keep your information that relates to any submission of information that you may make through your use of the Service and any other information provided by Own Up or Own Up affiliates in the processing of any submission of information, whether or not you are eligible for the Provider's products or services. You agree to notify any particular Provider directly if you no longer want them to keep your information."
  },
  {
    content:
      "As a prospective borrower or applicant of a Provider's products or services, you agree that when you nominate a co-signer/co-applicant, Own Up may share Provider responses relating to your submission with your nominated co-signer/co-applicant, and, as a co-signer/co-applicant, we may share Provider responses relating to your submission with the prospective borrower or applicant."
  }
];

const sharingInformation = [
  {
    header: 'Providers.',
    content: (
      <PrivacyBodyTextInline>
        We may share your information with Providers for the purpose of providing the Service to you
        such that they may provide you with information about your request for prequalified rates,
        offers, and information related to other products and services they offer.
        <BulletedList data={sharingWithProviders} />
      </PrivacyBodyTextInline>
    )
  },
  {
    header: 'Affiliates.',
    content:
      "We may share information with our parent company and any of our parent company's subsidiaries."
  },
  {
    header: 'Service providers.',
    content:
      'We may share your personal information with third parties who work on behalf of, or with, us such as vendors, processors, agents and other representatives. Service providers assist us with a variety of functions. This includes, for example, assisting with customer service-related functions, providing advertising (including interest-based advertising) and analytics services (e.g., Google Analytics and Facebook Custom Audiences), helping us verify your identity, obtain your credit report, providing website hosting services, assisting with auditing functions, and helping us with marketing and related research activities.'
  },
  {
    header: 'Marketing partners.',
    content:
      'We may share your personal information with third-party marketing partners including partners who we conduct joint-marketing activities with or who we offer a co-branded service with. Marketing partners also include those entities who maintain a link to our Services on their site, or an Own Up widget on their site, when you interact with that widget or click from their site to our Services.'
  },
  {
    header: 'Other parties when required by law or as necessary to protect our users and services.',
    content:
      'Own Up may share your information where required to do so by law or subpoena or if we reasonably believe that such action is necessary to (a) comply with the law and the reasonable requests of law enforcement; (b) enforce our Terms and Conditions or to protect the security or integrity of our Service; and/or (c) exercise or protect the rights, property, or personal safety of Own Up, our Users or others.'
  },
  {
    header: 'Business transfers.',
    content:
      'We may buy or sell/divest/transfer Own Up (including any shares in Own Up), or any combination of its products, services, assets and/or businesses. Your information, which may include NPI, usernames and email addresses, and other user information related to the Service may be among the items sold or otherwise transferred in these types of transactions. We may also sell, assign or otherwise transfer such information in the course of corporate divestitures, mergers, acquisitions, bankruptcies, dissolutions, reorganizations, liquidations, similar transactions or proceedings involving all or a portion of Own Up.'
  },
  {
    header:
      'Training and development of our automated chatbot "Katina" or other artificial intelligence bots.',
    content: ''
  },
  {
    header: 'Aggregated or de-identified information.',
    content:
      'We may also aggregate or de-identify data and may share that data without restriction.'
  },
  {
    header: 'Otherwise with your consent or at your direction.',
    content:
      'In addition to the sharing described in this Privacy Policy, we may share information about you with third-parties whenever you consent to or direct such sharing.'
  }
];

const sharingOfYourInformation = {
  header: 'Sharing of your information',
  content: [
    <TextGridItem key={0}>
      <PrivacyBodyText>
        No mobile information, including Personally Identifiable Information (PII), will be shared
        with third parties/affiliates for marketing/promotional purposes. This information is never
        sold, rented, released, or traded to others without your prior consent or legal obligation.
        All other use case categories exclude text messaging originator opt-in data and consent this
        information will not be shared with any third parties.
      </PrivacyBodyText>
    </TextGridItem>,
    <br key={1} />,
    <TextGridItem key={2}>
      <PrivacyBodyText>
        We may share the information we collect or receive from you in a variety of circumstances,
        including as listed below. Where appropriate, we will limit sharing of your information in
        accordance with the choices you have provided us in response to our{' '}
        <OwnUpUnderlineLink to="/assets/privacy/glba-notice.pdf" component={Link}>
          Privacy Notice
        </OwnUpUnderlineLink>
        .
      </PrivacyBodyText>
    </TextGridItem>,
    <TextGridItem key={3}>
      <BulletedList data={sharingInformation} />
    </TextGridItem>
  ]
};

/**
 * Content for Section 5.
 */
const storageAndSecurityOfPersonalInformationList = [
  {
    header: 'Information Security Officer.',
    content:
      'We have designated an Information Security Officer (ISO) who has the duty to (i) ensure by contract that third parties to whom the company transfers personal information will adequately protect the information and use it only for specified lawful purposes; (ii) protect Your personal data using appropriate security measures; (iii) notify authorities of personal data breaches when required; (iv) obtain appropriate consents for processing data; (v) keep records detailing data processing; (vi) train privacy personnel and employees.'
  },
  {
    header: 'Information Storage.',
    content:
      'Own Up stores Your Personal Information on our computers, and we believe we have reasonable security measures in place including administrative, technical and physical safeguards that are appropriate for the size and complexity of our business to protect Your Personal Information including encryption of any personally identifying information.'
  },
  {
    header: 'Security Breach Notice.',
    content:
      'When required by applicable regulations, we will notify you of any unencrypted data that we maintain that includes Personal Information, we will notify You of any unencrypted Personal Information that was, or is reasonably believed to have been, acquired by an unauthorized person.'
  }
];

const howWeStoreAndProtectYourInformationList = [
  {
    header: 'Keeping your information safe.',
    content: [
      <PrivacyBodyText key={1}>
        Own Up takes technical and organizational measures to protect your data against accidental
        or unlawful destruction or accidental loss, alteration, unauthorized disclosure or access.
        These measures vary depending on the sensitivity of the information we have collected from
        you. However, no method of transmission over the Internet or via mobile device, or method of
        electronic storage, is absolutely secure. Therefore, while we strive to use commercially
        acceptable means to protect your information, we cannot guarantee its absolute security. Own
        Up is not responsible for the functionality or security measures of any third-party or
        Provider.
      </PrivacyBodyText>,
      <br key={2} />,
      <PrivacyBodyText key={3}>
        To protect your privacy and security, we take steps (such as requesting a unique password)
        to verify your identity before granting you access to your account. You are responsible for
        maintaining the secrecy of your unique password and account information, and for controlling
        access to your email communications from Own Up, at all times. Do not share your password
        with anyone and do limit your access to your computer or other devices by signing off after
        you have finished accessing your account.
      </PrivacyBodyText>,
      <br key={4} />,
      <PrivacyBodyText key={5}>
        For information about how to protect yourself against identity theft, please refer to the{' '}
        <OwnUpUnderlineLink
          href="https://www.ftc.gov/news-events/media-resources/identity-theft-and-data-security"
          target="_blank"
          rel="noopener"
        >
          Federal Trade Commission&apos;s website
        </OwnUpUnderlineLink>
        .
      </PrivacyBodyText>
    ]
  },
  {
    header: 'Storage and Security of Personal Information.',
    content: <OrderedList level={3} data={storageAndSecurityOfPersonalInformationList} />
  }
];

const howWeStoreAndProtectYourInformation = {
  header: 'How we store and protect your information',
  content: (
    <TextGridItem key={1}>
      <OrderedList level={2} data={howWeStoreAndProtectYourInformationList} />
    </TextGridItem>
  )
};

/**
 * Content for Section 6.
 */
const choices = [
  {
    header: 'Your account information and settings',
    content: (
      <PrivacyBodyText>
        If you choose to set up a profile, you may update your account information at any time by
        logging into your account and changing your profile settings. You can also contact us at{' '}
        <OwnUpUnderlineLink href={`mailto:${ownUpSupportEmail}`}>
          {ownUpSupportEmail}
        </OwnUpUnderlineLink>{' '}
        to change your preferences.
      </PrivacyBodyText>
    )
  },
  {
    header: 'Email promotions',
    content: (
      <PrivacyBodyText>
        You can stop receiving promotional email communications from us by clicking on the
        &quot;unsubscribe link&quot; provided in such communications. We make every effort to
        promptly process all unsubscribe requests. You may not opt out of Service-related
        communications (e.g., account verification, transaction-related updates, changes/updates to
        features of the Service, and technical and security notices). If you have any questions
        about reviewing or modifying your account information, you can contact us directly at{' '}
        <OwnUpUnderlineLink href={`mailto:${ownUpSupportEmail}`}>
          {ownUpSupportEmail}
        </OwnUpUnderlineLink>
        .
      </PrivacyBodyText>
    )
  },
  {
    header: 'Opt-out and annual notice',
    content: (
      <PrivacyBodyText>
        If you have a financial product or service with us, we will use and share any information
        that we collect from or about you in accordance with our{' '}
        <OwnUpUnderlineLink to="/assets/privacy/glba-notice.pdf" component={Link}>
          Privacy Notice
        </OwnUpUnderlineLink>
        , which offers you certain choices with respect to the use and sharing of your personal
        information, including the right to opt-out to the sharing of certain information.
      </PrivacyBodyText>
    )
  },
  {
    header: 'Text message opt-out',
    content:
      'If you are receiving text messages from us and wish to stop receiving them, simply respond with either "STOP" or "UNSUBSCRIBE" to the number from which you received the message. Once we receive your message, you will no longer receive further text messages from us.'
  }
];

const yourChoicesAboutYourInformation = {
  header: 'Your choices about your information',
  content: (
    <TextGridItem>
      <OrderedList level={2} data={choices} />
    </TextGridItem>
  )
};

/**
 * Content for Section 7.
 */
const howLongWeKeepYourUserContent = {
  header: 'How long we keep your user content',
  content: (
    <TextGridItem>
      <PrivacyBodyText>
        Following termination or deactivation of your User account, Own Up may retain your profile
        information for an additional period of time for backup, archival, or audit purposes. In
        certain circumstances, we are required by Federal and State regulations to store various
        information. In order to comply with those requirements, we may be unable to delete your
        information from our database until the legally required retention period expires.
      </PrivacyBodyText>
    </TextGridItem>
  )
};

/**
 * Content for Section 8.
 */
const childrensPrivacy = {
  header: "Children's privacy",
  content: (
    <TextGridItem>
      <PrivacyBodyText>
        Own Up does not knowingly collect or solicit any information from anyone under the age of 16
        or allow such persons to register as Users. In the event that we learn that we have
        collected personal information from a child under the age of 16, we will promptly delete
        that information. If you believe that we might have collected any information from a child
        under 16, please contact us at{' '}
        <OwnUpUnderlineLink href={`mailto:${ownUpSupportEmail}`}>
          {ownUpSupportEmail}
        </OwnUpUnderlineLink>
        .
      </PrivacyBodyText>
    </TextGridItem>
  )
};

/**
 * Content for Section 9.
 */
const linksToOtherWebSitesAndServices = {
  header: 'Links to other web sites and services',
  content: (
    <TextGridItem>
      <PrivacyBodyText>
        We are not responsible for the practices employed by websites or services linked to or from
        the Service or to which you direct Own Up to retrieve information, including the information
        or content contained therein. Please remember that when you use a link to go from the
        Service to another website, our Privacy Policy does not apply to third-party websites or
        services. Your browsing and interaction on any third-party website or service, including
        those that have a link or advertisement on our website, are subject to that
        third-party&apos;s own rules and policies. In addition, you agree that we are not
        responsible for, and we do not have control over, any third-parties that you authorize to
        access your User Content. If you are using a third-party website or service and you allow
        such a third-party access to your User Content, you do so at your own risk. Own Up is also
        not responsible for sites directing you to Own Up. Our Terms of Service and Privacy Policy
        do not apply to those third-party sites either.
      </PrivacyBodyText>
    </TextGridItem>
  )
};

/**
 * Content for Section 10.
 */
const { street, unit, city, state, zip } = ownUpAddress;

const yourCaliforniaPrivacyRights = {
  header: 'Your California privacy rights',
  content: [
    <TextGridItem key={1}>
      <PrivacyBodyText>
        California Civil Code Section 1798.83, also known as the &quot;Shine The Light&quot; law,
        permits Users who are California residents to request and obtain from us once a year, free
        of charge, information about the personal information (if any) we disclosed to third-parties
        for direct marketing purposes in the preceding calendar year. If applicable, this
        information would include a list of the categories of personal information that were shared
        and the names and addresses of all third-parties with which we shared information in the
        immediately preceding calendar year. If you are a California resident and would like to make
        such a request, please submit your request in writing to:
      </PrivacyBodyText>
    </TextGridItem>,
    <TextGridItem key={2}>
      <PrivacyBodyText>
        Attention: Legal Department
        <br />
        Own Up
        <br />
        {street}, {unit}
        <br />
        {city}, {state} {zip}
        <br />
      </PrivacyBodyText>
    </TextGridItem>,
    <br key={3} />,
    <TextGridItem key={4}>
      <PrivacyBodyText>
        The California Consumer Privacy Act (&quot;CCPA&quot;) also affords California residents
        certain rights over their personal information (subject to certain exceptions). Our CCPA
        Privacy Notice, which supplements this Privacy Policy, outlines those rights and explains
        how they may be exercised.
      </PrivacyBodyText>
    </TextGridItem>
  ]
};

/**
 * Content for Section 11.
 */
const complaintsAndHowToContactUs = {
  header: 'Complaints and how to contact us',
  content: (
    <TextGridItem>
      <PrivacyBodyText>
        To make a complaint about a privacy-related matter, or to request access to or correction of
        your personal information, or if you have any questions about this Privacy Policy or the
        Service, please contact us at{' '}
        <OwnUpUnderlineLink href={`mailto:${ownUpSupportEmail}`}>
          {ownUpSupportEmail}
        </OwnUpUnderlineLink>{' '}
        or by phone at{' '}
        <OwnUpUnderlineLink href={`tel:${ownUpPhoneClientFormat}`}>{ownUpPhone}</OwnUpUnderlineLink>
        . We will review all complaints and determine whether the matter needs further
        investigation. In the event an investigation is conducted concerning your personal
        information, we will notify you of the outcome of the investigation.
      </PrivacyBodyText>
    </TextGridItem>
  )
};

/**
 * Content for Section 12.
 */
const changesToOurPrivacyPolicy = {
  header: 'Changes to our Privacy Policy',
  content: (
    <TextGridItem>
      <PrivacyBodyText>
        Own Up may modify or update this Privacy Policy from time to time to reflect changes in our
        business and practices, and so you should review this page periodically
        (www.ownup.com/privacy). If we make changes to this Privacy Policy, we will revise the
        &quot;Last Updated&quot; date at the top of this policy. If we make a material change to
        this Privacy Policy, we will notify you as required by law. Your continued use of the
        Service following any changes signifies your acceptance of our Privacy Policy as modified.
      </PrivacyBodyText>
    </TextGridItem>
  )
};

/** Content for Section 13 */
const useOfArtificialIntelligence = {
  header: 'Use of Artificial Intelligence',
  content: (
    <TextGridItem>
      <PrivacyBodyText>
        Our website utilizes an AI (Artificial Intelligence) Chatbot &quot;Katina&quot;, which is
        programmed to provide helpful information and assistance based on available data and
        predefined algorithms. While the Chatbot aims to offer accurate and helpful responses, its
        information may not always be exhaustive, up-to-date, or tailored to individual
        circumstances. It should not be considered a substitute for educational information received
        from one of our Home Advisors. Users are encouraged to verify critical information and
        consult with our office for specific concerns or decisions. We do not assume liability for
        actions taken based on the Chatbot&apos;s responses. Any interaction with our AI Chatbot
        does not constitute the rendering of advice. By using our website and its Chatbot, you
        acknowledge and agree to these terms.
      </PrivacyBodyText>
    </TextGridItem>
  )
};

export const sections = [
  collectionOfYourInformation,
  doNotTrackSignals,
  useOfYourInformation,
  sharingOfYourInformation,
  howWeStoreAndProtectYourInformation,
  yourChoicesAboutYourInformation,
  howLongWeKeepYourUserContent,
  childrensPrivacy,
  linksToOtherWebSitesAndServices,
  yourCaliforniaPrivacyRights,
  complaintsAndHowToContactUs,
  changesToOurPrivacyPolicy,
  useOfArtificialIntelligence
];
